var render = function () {
  var _vm$APPLICATION, _vm$APPLICATION$data;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-overlay', {
    attrs: {
      "value": _vm.isLoading,
      "z-index": "9999"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c('div', [!_vm.ACTUAL_VERSION ? _c('v-alert', {
    staticClass: "error-alert",
    attrs: {
      "max-width": "350",
      "color": "red",
      "dense": "",
      "type": "error"
    }
  }, [_vm._v(" Outdated version of application ")]) : _vm._e(), _c('ApplicationNavigation', {
    attrs: {
      "sections": _vm.sections,
      "userRole": "client"
    }
  }), _c('v-row', {
    ref: "sections",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "lg": "9"
    }
  }, [_c('ApplicationSection', {
    key: _vm.applicationSection.key,
    attrs: {
      "section": _vm.applicationSection,
      "existedData": (_vm$APPLICATION = _vm.APPLICATION) === null || _vm$APPLICATION === void 0 ? void 0 : (_vm$APPLICATION$data = _vm$APPLICATION.data) === null || _vm$APPLICATION$data === void 0 ? void 0 : _vm$APPLICATION$data.common
    }
  })], 1)], 1), _c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isReady,
      expression: "isReady"
    }],
    ref: "sections",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "lg": "9"
    }
  }, _vm._l(_vm.blocks, function (block) {
    return _c('div', {
      key: block.key
    }, [_c('p', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.isLoading && block.key !== 'complain',
        expression: "!isLoading && block.key !== 'complain'"
      }],
      staticClass: "block-title"
    }, [_vm._v(" " + _vm._s(block.heading) + " ")]), _vm._l(block.sections, function (section) {
      var _vm$APPLICATION2, _vm$APPLICATION2$data;

      return _c('div', {
        key: section.key
      }, [section.visibility ? _c(section.component, {
        key: section.key,
        tag: "component",
        attrs: {
          "section": section,
          "existedData": (_vm$APPLICATION2 = _vm.APPLICATION) === null || _vm$APPLICATION2 === void 0 ? void 0 : (_vm$APPLICATION2$data = _vm$APPLICATION2.data) === null || _vm$APPLICATION2$data === void 0 ? void 0 : _vm$APPLICATION2$data[section.key],
          "userRole": "client"
        },
        on: {
          "componentReady": _vm.componentReady
        }
      }) : _vm._e()], 1);
    })], 2);
  }), 0)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }