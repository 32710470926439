<template>
<div>
  <v-overlay
    :value="isLoading"
    z-index="9999"
  >
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>

  <div>
    <v-alert
      v-if="!ACTUAL_VERSION"
      class="error-alert"
      max-width="350"
      color="red"
      dense
      type="error"
    >
      Outdated version of application
    </v-alert>

    <ApplicationNavigation
      :sections="sections"
      userRole="client"
    />

    <v-row justify="center" ref="sections">
        <v-col lg="9">
          <ApplicationSection
            :section="applicationSection"
            :existedData="APPLICATION?.data?.common"
            :key="applicationSection.key"
          />
        </v-col>
      </v-row>

    <v-row v-show="isReady" justify="center" ref="sections">
      <v-col lg="9">
        <div v-for="block in blocks" :key="block.key">
          <p
            v-show="!isLoading && block.key !== 'complain'"
            class="block-title"
          >
            {{ block.heading }}
          </p>
          <div v-for="section in block.sections" :key="section.key">
            <component
              v-if="section.visibility"
              :is="section.component"
              :key="section.key"
              :section="section"
              :existedData="APPLICATION?.data?.[section.key]"
              @componentReady="componentReady"
              userRole="client"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getCurrentUserId } from '@/services/firebase';
import { USER_ROLES } from '@/models/helpers/consts';

import ApplicationNavigation from '@/components/ApplicationNavigation.vue';

import AppVerisonCheckMixin from '@/mixins/AppVerisonCheckMixin';

export default {
  components: {
    ApplicationNavigation,

    ApplicationSection: () => import('@/components/ApplicationSections/ApplicationSection.vue'),
    SubApplications: () => import('@/components/SubApplications/SubApplications.vue'),
    CompaniesSection: () => import('@/components/ApplicationSections/CompaniesSection.vue'),
    InsuranceSection: () => import('@/components/ApplicationSections/InsuranceSection.vue'),
    FutureGoalsSection: () => import('@/components/ApplicationSections/FutureGoalsSection.vue'),
    DependantsSection: () => import('@/components/ApplicationSections/DependantsSection.vue'),
    RealEstateAssetsSection: () => import('@/components/ApplicationSections/RealEstateAssetsSection.vue'),
    IncomesSection: () => import('@/components/ApplicationSections/IncomesSection.vue'),
    OtherAssetsSection: () => import('@/components/ApplicationSections/OtherAssetsSection.vue'),
    LiabilitiesSection: () => import('@/components/ApplicationSections/LiabilitiesSection.vue'),
    LivingExpensesSection: () => import('@/components/ApplicationSections/LivingExpensesSection.vue'),
    RetirementSection: () => import('@/components/ApplicationSections/RetirementSection.vue'),
    LoanStructureSection: () => import('@/components/ApplicationSections/LoanStructureSection.vue'),
    ChecklistSection: () => import('@/components/ApplicationSections/ChecklistSection.vue'),
    FinalStatementSection: () => import('@/components/ApplicationSections/FinalStatementSection.vue'),
    FileManagerSection: () => import('@/components/ApplicationSections/FileManagerSection.vue'),

    TheBottomNavigation: () => import('@/components/TheBottomNavigation.vue'),
  },

  mixins: [AppVerisonCheckMixin],

  data() {
    return {
      isLoading: false,
      componentsReady: 0,
      sections: [],

      blocks: [
        {
          key: 'applicant_info',
          heading: 'Applicant info',
          sections: [
            {
              key: 'sub_application',
              component: 'SubApplications',
              title: 'Applicants',
              icon: 'mdi-account-multiple',
              visibility: true,
              access: ['client', 'adviser'],
              commentShow: true,
            },
            {
              key: 'companies',
              component: 'CompaniesSection',
              title: 'Companies and Trusts',
              icon: 'mdi-briefcase-outline',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'companies',
              commentShow: true,
            },
            {
              key: 'dependants',
              component: 'DependantsSection',
              title: 'Dependants (if applicable)',
              icon: 'mdi-baby-carriage',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'dependants',
              commentShow: true,
            },
          ],
        },
        {
          key: 'assets_real_estate',
          heading: 'Assets & real estate',
          sections: [
            {
              key: 'real_estate_assets',
              component: 'RealEstateAssetsSection',
              title: 'Existing Real Estate Assets',
              icon: 'mdi-home-group',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'estateAssets',
              commentShow: true,
            },
            {
              key: 'other_assets',
              component: 'OtherAssetsSection',
              title: 'Assets',
              icon: 'mdi-package-variant',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'otherAssets',
              commentShow: true,
            },
          ],
        },
        {
          key: 'income',
          heading: 'Income',
          sections: [
            {
              key: 'incomes',
              component: 'IncomesSection',
              title: 'Incomes',
              icon: 'mdi-currency-usd',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'incomes',
              commentShow: true,
            },
          ],
        },
        {
          key: 'expenses',
          heading: 'Expenses',
          sections: [
            {
              key: 'liabilities',
              component: 'liabilitiesSection',
              title: 'Liabilities and Credit cards',
              icon: 'mdi-calendar-alert',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'liabilities',
              commentShow: true,
            },
            {
              key: 'living_expenses',
              component: 'LivingExpensesSection',
              title: 'Living expenses',
              icon: 'mdi-currency-usd',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'livingExpenses',
              commentShow: true,
            },
          ],
        },
        {
          key: 'other_information',
          heading: 'Other important information',
          sections: [
            {
              key: 'insurance',
              component: 'InsuranceSection',
              title: 'Insurance Details',
              icon: 'mdi-shield-check-outline',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'insurance',
              commentShow: true,
            },
            {
              key: 'future_goals',
              component: 'FutureGoalsSection',
              title: 'Future finance goals',
              icon: 'mdi-flag-checkered',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'futureGoals',
              commentShow: true,
            },
            {
              key: 'retirement',
              component: 'RetirementSection',
              title: 'Retirement & Financial Consideration',
              icon: 'mdi-account-cowboy-hat',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'retirement',
              commentShow: true,
            },
          ],
        },
        {
          key: 'documents',
          heading: 'Documents',
          sections: [
            {
              key: 'checklist',
              component: 'ChecklistSection',
              title: 'Documents Checklist',
              icon: 'mdi-pen',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'checklist',
              commentShow: true,
            },
            {
              key: 'files',
              component: 'FileManagerSection',
              title: 'Files',
              icon: 'mdi-archive-outline',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'files',
              commentShow: true,
            },
          ],
        },
        {
          key: 'pre-settlement_care',
          heading: 'Pre-settlement care',
          sections: [
            {
              key: 'final_statement',
              component: 'FinalStatementSection',
              title: 'Final Statement of advice',
              icon: 'mdi-file-check',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'files',
              commentShow: true,
            },
            {
              key: 'loan_structure',
              component: 'LoanStructureSection',
              title: 'Loan structure',
              icon: 'mdi-file-tree',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'loanStructure',
              commentShow: true,
            },
          ],
        },
      ],

      applicationSection: {
        key: 'common',
        component: 'ApplicationSection',
        title: 'Application Settings',
        icon: '',
        visibility: true,
        access: ['client'],
        payloadKey: 'common',
        commentShow: true,
      },
    };
  },

  computed: {
    ...mapGetters([
      'APPLICATION',
      'ACTUAL_VERSION',
    ]),

    currentUser() {
      return getCurrentUserId();
    },

    sectionsReady() {
      let sectionsCount = 0;

      this.blocks.forEach((block) => {
        sectionsCount += block.sections.length;
      });

      return sectionsCount;
    },

    isReady() {
      const isReady = Boolean(this.sectionsReady === this.componentsReady);

      return isReady;
    },
  },

  methods: {
    componentReady() {
      this.componentsReady += 1;
    },

    disableComponent() {
      const appClientId = this.APPLICATION?.data?.common?.client?.uid;
      const checker = Boolean(appClientId !== this.currentUser);
      return checker;
    },

    userCanView() {
      const view = this.APPLICATION?.data?.common?.view;

      const canView = Boolean(view !== USER_ROLES.adviser);

      return canView;
    },
  },

  async created() {
    this.isLoading = true;

    this.$store.commit('setIsClient', true);

    const payload = {
      id: this.$route.params.id,
      parentId: null,
    };

    await this.$store.dispatch('getApplication', payload);

    this.blocks.forEach((block) => {
      this.sections.push(...block.sections);
    });

    if (!this.userCanView()) {
      this.$router.push({ name: 'ClientApplications' });
    }

    this.applicationVersionCheck();

    this.isLoading = false;
  },

  watch: {
    'APPLICATION.data.common.client.uid': {
      handler() {
        const status = this.disableComponent();
        this.$store.commit('setIsOwner', status);
      },
    },
  },
};
</script>

<style lang="scss">
  .firebase-emulator-warning {
    display: none;
  }

  .block-title {
    font-size: 35px;
    font-weight: 600;
  }

  .debug {
    padding: 10px;
    position: fixed;
    top: 100px;
    right: 50px;
    border: 1px solid grey;
    background: #fff;
  }
</style>
